import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Paper, Typography, alpha } from '@mui/material';
//
import Image from '../Image';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
  onFileUpload: PropTypes.func,
};

export default function UploadSingleFile({ error = false, file, helperText, sx, onFileUpload, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });
  const handleFileUpload = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onFileUpload(acceptedFiles[0]); // Assuming only one file is uploaded
    }
  };
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file && {
            padding: '1% 0',
          }),
        }}
      >
        <input {...getInputProps({ onChange: handleFileUpload })} />

        <BlockContent />
        {/* Display a message when file is uploaded */}
        {file && (
          <Paper
          className='mx-3 mb-2'
            variant="outlined"
            sx={{
              py: 1,
              px: 2,
              mt: 3,
              borderColor: 'success.light',
              bgcolor: (theme) => alpha(theme.palette.success.main, 0.08),
            }}
          >
            <Box sx={{ my: 1 }}>
              <Typography className='text-center' variant="subtitle2" noWrap>
                File uploaded successfully!
              </Typography>

              <Typography className='text-center' key={error.code} variant="caption" component="p">
               
               {file.path}
              </Typography>
            </Box>
          </Paper>
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  );
}
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  radiologistReports: [],
};

const radiologistAllReportsSlice = createSlice({
  name: 'radiologistAllReports',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getReportsSuccess(state, action) {
      state.isLoading = false;
      state.radiologistReports = action.payload;
    },
  },
});

export default radiologistAllReportsSlice.reducer;

export const { startLoading, hasError, getReportsSuccess } = radiologistAllReportsSlice.actions;

export const getRadiologistAllReports = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.get('radiologist/get/all/reports');
    dispatch(getReportsSuccess(response?.data?.data));
  } catch (error) {
    dispatch(hasError(error));
  }
};

import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    radiologistCheckedReports: [],
};

const radiologistAllCheckedReportsSlice = createSlice({
    name: 'radiologistAllCheckedReports',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getRadiologistAllCheckedReportsSuccess(state, action) {
            state.isLoading = false;
            state.radiologistCheckedReports = action.payload;
        },
    },
});

export default radiologistAllCheckedReportsSlice.reducer;

export const { startLoading, hasError, getRadiologistAllCheckedReportsSuccess } = radiologistAllCheckedReportsSlice.actions;

export const getradiologistAllCheckedReports = () => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axios.get('radiologist/get/checked/reports');
        dispatch(getRadiologistAllCheckedReportsSuccess(response?.data?.data));
    } catch (error) {
        dispatch(hasError(error));
    }
};
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
// import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
// import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';




// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Home /> },
    { path: '/login/admin', element: <Login /> },
    { path: '/codemail', element: <CodeMail /> },
    { path: '/login/hospital', element: <CompanyLogin /> },
    { path: '/login/radiologist', element: <ClinicLogin /> },
    { path: '/forget', element: <Register /> },
    { path: '/subscription', element: <Subscription /> },
    { path: '/hospital-registeration', element: <ResetPassword usertype={'hospital'} /> },
    { path: '/radiologist-registeration', element: <ResetPassword usertype={'radiologist'} /> },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [

        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <GeneralAnalytics /> },
        { path: 'hospital', element: <Hospital /> },
        { path: 'report', element: <Report /> },
        { path: 'view-report/:id', element: <ViewReport /> },
        { path: 'edithospital/:id', element: <EditHospital /> },
        { path: 'adddorm', element: <AddDorm /> },
        { path: 'element', element: <Element /> },
        { path: 'editelement/:id', element: <EditElement /> },
        { path: 'companyProfile', element: <CompanyProfile /> },
        { path: 'addelement', element: <AddElement /> },
        { path: 'magictype', element: <Magictype /> },
        { path: 'radiologists', element: <Radiologists /> },
        { path: 'editmagictype/:id', element: <EditMagictype /> },
        { path: 'addmagictype', element: <AddMagictype /> },
        { path: 'tag', element: <Tag /> },
        { path: 'edittag/:id', element: <EditTag /> },
        { path: 'addtag', element: <AddTag /> },
        { path: 'rarity', element: <Rarity /> },
        { path: 'editrarity/:id', element: <EditRarity /> },
        { path: 'addrarity', element: <AddRarity /> },
        { path: 'spell', element: <Spell /> },
        { path: 'editspell/:id', element: <EditSpell /> },
        { path: 'addspell', element: <AddSpell /> },
        { path: 'editslot/:id', element: <EditSlot /> },
        { path: 'addslot', element: <AddSlot /> },
        { path: 'addslot/:id', element: <AddSlot /> },
        { path: 'slot/:id', element: <Effect /> },
        { path: 'appointment', element: <Appointment /> },
        { path: 'card', element: <Card /> },
        { path: 'editcard/:id', element: <EditCard /> },
        { path: 'addcard', element: <AddCard /> },
        { path: 'character', element: <Character /> },
        { path: 'editcharacter/:id', element: <EditCharacter /> },
        { path: 'addcharacter', element: <AddCharacter /> },
        { path: 'CardDetail/:id', element: <Profile /> },
        { path: 'Form', element: <Form /> },
        { path: 'addForm', element: <AddForm /> },
        { path: 'editform/:id', element: <EditForms /> },
        { path: 'radiologist', element: <Radiologist /> },
        { path: 'addcheckinInfo', element: <AddCheckinInfo /> },
        { path: 'editcheckinInfo/:id', element: <EditCheckinInfo /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
      ],
    },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const CompanyLogin = Loadable(lazy(() => import('../pages/auth/CompanyLogin')));
const ClinicLogin = Loadable(lazy(() => import('../pages/auth/ClinicLogin')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const CodeMail = Loadable(lazy(() => import('../pages/auth/CodeMail')));

const Subscription = Loadable(lazy(() => import('../pages/dashboard/Subscription')));
const Home = Loadable(lazy(() => import('../pages/dashboard/Subscription')));
const Hospital = Loadable(lazy(() => import('../pages/dashboard/Subscription/Hospital')));
const Report = Loadable(lazy(() => import('../pages/dashboard/Subscription/Report')));
const ViewReport = Loadable(lazy(() => import('../pages/dashboard/Subscription/ViewReport')));
const EditHospital = Loadable(lazy(() => import('../pages/dashboard/Subscription/EditHospital')));
const AddDorm = Loadable(lazy(() => import('../pages/dashboard/Subscription/AddDorm')));

const Element = Loadable(lazy(() => import('../pages/dashboard/Company/Element')));
const EditElement = Loadable(lazy(() => import('../pages/dashboard/Company/EditElement')));
const CompanyProfile = Loadable(lazy(() => import('../pages/dashboard/Company/CompanyProfile')));
const AddElement = Loadable(lazy(() => import('../pages/dashboard/Company/AddElement')));

const Magictype = Loadable(lazy(() => import('../pages/dashboard/Clinic/Magictype')));
const Radiologists = Loadable(lazy(() => import('../pages/dashboard/Clinic/Radiologists')));
const EditMagictype = Loadable(lazy(() => import('../pages/dashboard/Clinic/EditMagictype')));
const AddMagictype = Loadable(lazy(() => import('../pages/dashboard/Clinic/AddMagictype')));

const Tag = Loadable(lazy(() => import('../pages/dashboard/Student/Tag')));
const EditTag = Loadable(lazy(() => import('../pages/dashboard/Student/EditTag')));
const AddTag = Loadable(lazy(() => import('../pages/dashboard/Student/AddTag')));

const Rarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/Rarity')));
const EditRarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/EditRarity')));
const AddRarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/AddRarity')));

const Spell = Loadable(lazy(() => import('../pages/dashboard/Provider/Spell')));
const EditSpell = Loadable(lazy(() => import('../pages/dashboard/Provider/EditSpell')));
const AddSpell = Loadable(lazy(() => import('../pages/dashboard/Provider/AddSpell')));

const EditSlot = Loadable(lazy(() => import('../pages/dashboard/Slot/EditSlot')));
const AddSlot = Loadable(lazy(() => import('../pages/dashboard/Slot/AddSlot')));
const Effect = Loadable(lazy(() => import('../pages/dashboard/Slot/Effect')));

const Appointment = Loadable(lazy(() => import('../pages/dashboard/Appointment/Appointment')));

const Card = Loadable(lazy(() => import('../pages/dashboard/Card/Card')));
const EditCard = Loadable(lazy(() => import('../pages/dashboard/Card/EditCard')));
const AddCard = Loadable(lazy(() => import('../pages/dashboard/Card/AddCard')));
// const CardDetail = Loadable(lazy(() => import('../pages/dashboard/Card/CardDetails')));
const Profile = Loadable(lazy(() => import('../pages/dashboard/Card/Profile')));
const Form = Loadable(lazy(() => import('../pages/dashboard/ClinicPortal/Form')));
const AddForm = Loadable(lazy(() => import('../pages/dashboard/ClinicPortal/AddForm')));
const EditForms = Loadable(lazy(() => import('../pages/dashboard/ClinicPortal/EditFroms')));
const Radiologist = Loadable(lazy(() => import('../pages/dashboard/ClinicPortal/Radiologist')));
const AddCheckinInfo = Loadable(lazy(() => import('../pages/dashboard/ClinicPortal/AddCheckInfo')));
const EditCheckinInfo = Loadable(lazy(() => import('../pages/dashboard/ClinicPortal/EditCheckInfo')));

const Character = Loadable(lazy(() => import('../pages/dashboard/Character/Character')));
const EditCharacter = Loadable(lazy(() => import('../pages/dashboard/Character/EditCharacter')));
const AddCharacter = Loadable(lazy(() => import('../pages/dashboard/Character/AddCharacter')));

const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
// const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// // INVOICE
// const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
// const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
// const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
// const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
// const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
// const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
// const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// // USER
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
// const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// // APP
// const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
// const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
// const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// // MAIN
// const HomePage = Loadable(lazy(() => import('../pages/Home')));
// const About = Loadable(lazy(() => import('../pages/About')));
// const Contact = Loadable(lazy(() => import('../pages/Contact')));
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
// const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
// const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
// const Payment = Loadable(lazy(() => import('../pages/Payment')));
// const Page500 = Loadable(lazy(() => import('../pages/Page500')));
// const NotFound = Loadable(lazy(() => import('../pages/Page404')));
